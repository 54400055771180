import { useMemo } from "react";
import { useStorefrontCartHeavy } from "../..";
import {
  type StorefrontCartLineHeavyFragment,
  type StorefrontCartLine,
  type StorefrontCartHeavyFragment,
} from "../../client/MapmakerApi";
import { getAttributeValueByKey } from "./attributeUtils";

export type StickerOrderLineGroup = {
  fileId: string;
  orderId: string;
  lines: StorefrontCartLineHeavyFragment[];
};

export function isStickerOrderLine(
  line: StorefrontCartLineHeavyFragment
): boolean {
  return line.merchandise?.sku.startsWith("mapmaker.sticker.");
}

type GroupStickerLinesResult = {
  standardLines: StorefrontCartLineHeavyFragment[];
  purchaseStickerTokensLines: StorefrontCartLineHeavyFragment[];
  stickerOrderLines: StickerOrderLineGroup[];
};

export function groupStickerLines(
  lines: StorefrontCartLineHeavyFragment[] | undefined
): GroupStickerLinesResult {
  return (lines || []).reduce<GroupStickerLinesResult>(
    (result, line) => {
      const orderId = getOrderIdCart(line);
      if (isStickerOrderLine(line)) {
        const existingGroup = result.stickerOrderLines.find(
          (existingLine) => existingLine.orderId === orderId
        );
        if (existingGroup) {
          existingGroup.lines.push(line);
        } else {
          result.stickerOrderLines.push({
            fileId: line.merchandise?.sku.split("-")[0],
            orderId,
            lines: [line],
          });
        }
      } else {
        result.standardLines.push(line);
      }
      return result;
    },
    {
      standardLines: [],
      purchaseStickerTokensLines: [],
      stickerOrderLines: [],
    }
  );
}

const ORDER_ID_KEY = "Order ID";

function getOrderIdCart(
  line: Pick<StorefrontCartLine, "attributes">
): string | undefined {
  const orderIdAttribute = line.attributes.find(
    (attribute) => attribute.key === ORDER_ID_KEY
  );
  if (orderIdAttribute) {
    return orderIdAttribute.value ?? undefined;
  } else {
    return undefined;
  }
}

export function useStickerOrderLines() {
  const { cart, loading, error } = useStorefrontCartHeavy();
  const { stickerOrderLines } = useMemo(
    () => groupStickerLines(cart?.lines.edges.map(({ node }) => node)),
    [cart]
  );

  const stickerTokensInCart = useMemo(() => {
    return (
      cart?.lines.edges.reduce((tokens, { node: line }) => {
        return (
          tokens +
          parseFloat(
            getAttributeValueByKey(line.attributes, "Tokens Used", "0")
          )
        );
      }, 0) ?? 0
    );
  }, [cart?.lines]);

  return {
    stickerOrderLines,
    stickerTokensInCart,
    loading: loading,
    error: error,
  };
}

export function totalStickerTokensInCart(
  cart: StorefrontCartHeavyFragment
): number {
  return cart.lines.edges.reduce(
    (total, { node: line }) =>
      total +
      parseFloat(getAttributeValueByKey(line.attributes, "Tokens Used", "0")),
    0
  );
}

export function totalStickersWithoutTokenInCart(
  cart: StorefrontCartHeavyFragment
): number {
  return cart.lines.edges.reduce(
    (total, { node: line }) =>
      total +
      (isStickerOrderLine(line) &&
      getAttributeValueByKey(line.attributes, "Tokens Used", "0") === "0"
        ? line.quantity
        : 0),
    0
  );
}
