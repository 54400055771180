import clsx from "clsx";
import { useStorefrontCartLight } from "@mapmaker/frontend";
import { useGlobalLayout } from "./useLayout";
import { FaShoppingCart } from "@react-icons/all-files/fa/FaShoppingCart";
import { NumberBadge } from "@mapmaker/ui";
import styles from "./CartMenuButton.module.scss";

export default function CartMenuButton() {
  const { cart, loading } = useStorefrontCartLight();
  const { headerMode } = useGlobalLayout();
  const totalItems = (cart?.lines.edges ?? []).reduce(
    (total, { node }: any) => total + node.quantity,
    0
  );

  return (
    <div
      className={clsx({
        [styles.container]: true,
        [styles.loading]: loading,
        // @ts-ignore
        [styles[headerMode]]: true,
      })}
    >
      <FaShoppingCart fontSize={20} />
      <NumberBadge
        className={styles.badge}
        color={headerMode === "inverse" ? "var(--white)" : "accent"}
        textColor={headerMode === "inverse" ? "var(--grey)" : "accent"}
        flat
        count={totalItems}
      />
    </div>
  );
}
