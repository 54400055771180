import { gql } from "@apollo/client";
import { ChangeEvent, ComponentType } from "react";
import {
  useStorefrontUpdateCartLinesMutation,
  type StorefrontCartLineHeavyFragment,
} from "../../client/MapmakerApi";
import LineItem from "./LineItem";
import GiftNoteOptions from "./GiftNoteOptions";
import { useMapmakerAppConfig, useStorefrontCartId } from "../..";
import "./StandardLineItem.css";
import StandardLineDetails from "./StandardLineDetails";

gql`
  fragment StorefrontCartLineHeavy on StorefrontBaseCartLine {
    id
    quantity
    cost {
      subtotalAmount {
        amount
        currencyCode
      }
    }
    attributes {
      key
      value
    }
    merchandise {
      ... on StorefrontProductVariant {
        id
        title
        sku
        selectedOptions {
          name
          value
        }
        image {
          originalSrc
          transformedSrc(maxWidth: 512, crop: CENTER)
        }
        product {
          id
          handle
          tblProductType: metafield(namespace: "tbl", key: "productType") {
            key
            value
          }
        }
      }
    }
  }
`;

export type StandardLineDetailsProps = {
  line: StorefrontCartLineHeavyFragment;
  exclude?: string[];
};

export type StandardLineDetailsComponent =
  ComponentType<StandardLineDetailsProps>;

export interface IStandardLineProps {
  line: StorefrontCartLineHeavyFragment;
  DetailsComponent?: StandardLineDetailsComponent;
}

export default function StandardLine({
  line,
  DetailsComponent = StandardLineDetails,
}: IStandardLineProps) {
  const cartId = useStorefrontCartId();
  const { gotoProduct } = useMapmakerAppConfig();
  const [updateCartLine, { loading: updateLoading }] =
    useStorefrontUpdateCartLinesMutation();
  async function onSetQuantity(e: ChangeEvent<HTMLSelectElement>) {
    await updateCartLine({
      variables: {
        // @ts-expect-error
        cartId: cartId as string,
        lines: [
          {
            id: line.id,
            quantity: parseInt(e.target.value) || line.quantity,
          },
        ],
      },
    });
  }

  return (
    <LineItem
      title={line.merchandise.title}
      onProductNameClick={() => gotoProduct(line.merchandise.product.handle)}
      thumbnail={line.merchandise?.image?.transformedSrc}
      price={line.cost.subtotalAmount}
      lineIds={[line.id]}
      loading={updateLoading}
      discountAllocations={[]}
    >
      <div id="standard-line-item">
        <div>
          <label>Qty</label>
          <select value={line.quantity} onChange={onSetQuantity}>
            {Array(25)
              .fill(0)
              .map((_, i) => {
                const qty = i + 1;
                return (
                  <option key={qty} value={qty}>
                    {qty}
                  </option>
                );
              })}
          </select>
        </div>
        <div className="details">
          <DetailsComponent line={line} />
        </div>
        <div className="gift-note">
          <GiftNoteOptions line={line} />
        </div>
      </div>
    </LineItem>
  );
}
