import clsx from "clsx";
import { useMemo, useState, FocusEvent, useCallback } from "react";
import { Icon } from "semantic-ui-react";
import { type StorefrontCartLineHeavyFragment } from "../../client/MapmakerApi";
import { Button } from "@mapmaker/ui";
import {
  updateAttributesWithValue,
  useUpdatePartialLine,
} from "../../lib/storefront";
import "./GiftNoteOptions.css";

type GiftNoteDisplayProps = {
  line: StorefrontCartLineHeavyFragment;
};

export default function GiftNoteDisplay({ line }: GiftNoteDisplayProps) {
  const [updateCartLineItems, { loading }] = useUpdatePartialLine();

  const giftNote = useMemo(
    () => line.attributes.find((a) => a.key === "Gift Note")?.value,
    [line.attributes]
  );
  const [editing, setEditing] = useState(false);
  const [editedNote, setEditedNote] = useState<string>();

  const onNoteChange = useCallback(
    (e: FocusEvent<HTMLTextAreaElement>) => {
      setEditedNote(e.currentTarget.value || undefined);
    },
    [setEditedNote]
  );

  const saveGiftNote = useCallback(
    async (quantity: number) => {
      await updateCartLineItems(
        line.id,
        updateAttributesWithValue(
          line.attributes,
          "Gift Note",
          editedNote ?? undefined
        ),
        quantity
      );
      setEditing(false);
    },
    [editedNote, updateCartLineItems, line]
  );

  if (giftNote || editing) {
    return (
      <div
        className={clsx("gift-note-display", { editing })}
        onClick={editing ? undefined : () => setEditing(true)}
      >
        <div className="header">
          <Icon name="gift" />
          <span>Gift Note</span>
          {!editing && <Icon className="edit-button" name="pencil" />}
        </div>
        {editing ? (
          <div className="note">
            <textarea
              placeholder="Message will be included in the box with this item."
              defaultValue={giftNote ?? ""}
              rows={4}
              onChange={onNoteChange}
              disabled={loading}
            />
            {line.quantity > 1 ? (
              <>
                <Button
                  size="small"
                  outlined
                  onClick={() => saveGiftNote(1)}
                  disabled={loading}
                >
                  <Icon name="gift" />
                  {giftNote ? "Update One" : "Add to One"}
                </Button>
                <Button
                  size="small"
                  outlined
                  onClick={() => saveGiftNote(line.quantity)}
                  disabled={loading}
                >
                  <Icon name="gift" />
                  {giftNote ? "Update" : "Add to"}{" "}
                  {line.quantity === 2 ? "Both" : `All ${line.quantity}`}
                </Button>
              </>
            ) : (
              <Button
                size="small"
                outlined
                onClick={() => saveGiftNote(line.quantity)}
                loading={loading}
              >
                <Icon name="gift" />
                {giftNote ? "Update Note" : "Add Note"}
              </Button>
            )}
            <Button
              size="small"
              outlined
              text
              onClick={() => setEditing(false)}
              disabled={loading}
            >
              Cancel
            </Button>
          </div>
        ) : (
          <div className="note display" onClick={() => setEditing(true)}>
            {giftNote}
          </div>
        )}
      </div>
    );
  } else {
    return (
      <Button size="small" outlined onClick={() => setEditing(true)}>
        <Icon name="gift" />
        Add Gift Note
      </Button>
    );
  }
}
