import { useCallback, useState } from "react";
import { useAuthContext } from "@tbl/aws-auth";
import { Link, NavLink, useLocation } from "react-router-dom";
import { useMapmakerContext, useStorefrontCartLight } from "@mapmaker/frontend";
import clsx from "clsx";
import CartMenuButton from "./CartMenuButton";
import MobileMenu from "./MobileMenu";
import { MdMenu } from "@react-icons/all-files/md/MdMenu";
import { FaUser } from "@react-icons/all-files/fa/FaUser";
import { ImGift } from "@react-icons/all-files/im/ImGift";
import { HeaderMode, useGlobalLayout } from "./useLayout";
import BunnyLogo from "./images/bunny-logo.png";
import BunnyLogo2x from "./images/bunny-logo-2x.png";
import BunnyLogoInverse from "./images/bunny-logo-inverse.png";
import BunnyLogoInverse2x from "./images/bunny-logo-inverse-2x.png";
import { NumberBadge } from "@mapmaker/ui";
import styles from "./HeaderBar.module.scss";

const LogoSrc: Record<HeaderMode, { "1x": string; "2x": string }> = {
  normal: {
    "1x": BunnyLogo,
    "2x": BunnyLogo2x,
  },
  inverse: {
    "1x": BunnyLogoInverse,
    "2x": BunnyLogoInverse2x,
  },
};

export default function HeaderBar() {
  const [menuOpen, setMenuOpen] = useState(false);
  const { account } = useMapmakerContext();
  const { headerMode } = useGlobalLayout();
  const { pathname } = useLocation();
  const { cart } = useStorefrontCartLight();
  const onClose = useCallback(() => setMenuOpen(false), []);

  return (
    <>
      <MobileMenu open={menuOpen} onClose={onClose} />
      <div
        className={clsx({
          [styles.header]: true,
          // @ts-ignore
          [styles[headerMode]]: true,
        })}
      >
        <div className={styles.mobileHeader}>
          <MdMenu fontSize={32} onClick={() => setMenuOpen(true)} />

          <div className={styles.spacer} />

          <img
            className={styles.logo}
            src={LogoSrc[headerMode]["1x"]}
            srcSet={`${LogoSrc[headerMode]["2x"]} 2x`}
            alt="Thunder Bunny Labs logo"
          />

          {(cart?.lines.edges.length ?? 0) > 0 ? (
            <div>
              <Link to="/shop/cart">
                <CartMenuButton />
              </Link>
            </div>
          ) : null}
        </div>

        <div className={styles.desktopHeader}>
          <img
            className={styles.logo}
            src={LogoSrc[headerMode]["1x"]}
            srcSet={`${LogoSrc[headerMode]["2x"]} 2x`}
            alt="Thunder Bunny Labs logo"
          />

          <div
            className={clsx({
              [styles.menuItem]: true,
              [styles.active]: pathname.length <= 1,
            })}
          >
            <Link to="/">HOME</Link>
          </div>
          <div
            className={clsx({
              [styles.menuItem]: true,
              [styles.active]:
                pathname.startsWith("/shop") &&
                !pathname.startsWith("/shop/cart"),
            })}
          >
            <Link to="/shop">PRODUCTS</Link>
          </div>
          <div
            className={clsx({
              [styles.menuItem]: true,
              [styles.noDivider]: true,
              [styles.active]: pathname.startsWith("/mapmaker"),
            })}
          >
            <NavLink to="/mapmaker">
              YOUR PROJECTS
              <NumberBadge
                className={styles.badge}
                color={headerMode === "inverse" ? "var(--white)" : "accent"}
                textColor={headerMode === "inverse" ? "var(--grey)" : "accent"}
                flat
                count={account?.mapmakerFileCount ?? 0}
              />
            </NavLink>
          </div>

          <div className={styles.spacer} />

          <div
            className={clsx({
              [styles.menuItem]: true,
              [styles.active]: pathname === "/gift-guide",
            })}
          >
            <Link to="/gift-guide">
              <ImGift className={styles.linkIcon} /> GIFT GUIDE
            </Link>
          </div>

          <div
            className={clsx({
              [styles.menuItem]: true,
              [styles.active]: pathname === "/help",
            })}
          >
            <Link to="/help">HELP</Link>
          </div>

          <div
            className={clsx({
              [styles.menuItem]: true,
              [styles.active]: false,
            })}
          >
            <UserInfo />
          </div>

          <div
            className={clsx({
              [styles.menuItem]: true,
              [styles.noDivider]: true,
              [styles.noHighlight]: true,
              [styles.active]: pathname.startsWith("/shop/cart"),
            })}
          >
            <Link to="/shop/cart">
              <CartMenuButton />
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

function UserInfo() {
  const { user } = useAuthContext();
  if (user) {
    return <AuthenticatedUserInfo />;
  } else {
    return <UnauthenticatedUserInfo />;
  }
}

function AuthenticatedUserInfo() {
  return (
    <Link to="/account">
      <FaUser fontSize={20} style={{ marginRight: "-4px" }} />
    </Link>
  );
}

function UnauthenticatedUserInfo() {
  return <Link to="/account/login">LOG IN</Link>;
}
